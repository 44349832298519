'use strict';

Gri.module({
  name: 'scroll-alpha',
  ieVersion: null,
  $el: $('.scroll-alpha'),
  container: '.scroll-alpha',
  fn: function () {

    // region variables
    const $this = this.$el;
    // endregion

    // region run

    // endregion

    // region events
    $this.on('click', function () {
      $('html, body').animate({
        scrollTop: $(this).parent().next().offset().top
      }, 1000);
    });
    // endregion

    // region events functions

    // endregion

    // region functions

    // endregion

  }
});
